<!-- Name -->
<script lang="ts">
export default {
  name: 'app-header-brand'
};
</script>

<script lang="ts" setup>
import installations from './assets/data/installation-locations-forms.json';

import { useBrandsStore } from '~/store/brands';
import { useModalStore } from '~/store/modal';
import { useBrandsInfoStore } from '~/store/brandsInfo';

const { $api } = useNuxtApp();
const { gamas: storedGamas, loadedGama, setLoadedGama, selectedBrand, setSelectedGama, setGamas,getGamaComerciales} = useBrandsStore();
const modalStore = useModalStore();
const brandsInfoStore= useBrandsInfoStore();
const { showHideModal } = modalStore;
const isShowMenu = ref(false);
const router = useRouter();
const route = useRoute();
const contactForm = resolveComponent('ui/brand/modals/app-contact-form');
const loginModal = resolveComponent('ui/modals/app-login');
const menuItemSelected = ref('modelos');
const gamas = ref([]);

const customInfo= ref(await brandsInfoStore.getBrandCustomisation(route.params.marca));
const {isAuthenticated, user } = useSanctumAuth();

const {cyclesBrands}=storeToRefs(brandsInfoStore);

//computeds

const brandSelected=computed(()=>{
    return route.params.marca !=='marcos-cycles' ? route.params.marca: ( cyclesBrands.value.includes('Vespa') ? 'vespa': cyclesBrands.value[0].toLowerCase());
});

const locationsForms = computed(()=>installations[route.params.marca] ?? []);
//functions

const toogleMenu = () => {
  isShowMenu.value = !isShowMenu.value;
};

const setMenuselection = (selection) => {
  if(menuItemSelected.value !== selection){
  menuItemSelected.value = selection;
  }else{
    menuItemSelected.value = 'modelos';
  }
};

const showModalContact = () => {
  const payload = {
    showModal: true,
    component: contactForm,
    size: "full-mobile",
    props: {
      locations: locationsForms.value
    }
  };

  showHideModal(payload);
};

const showModalLogin = async () => {

if(!isAuthenticated.value){
  const payload = {
  showModal: true,
  component: loginModal,
  size: "full-mobile",
  props: {
      isBrand: true
  }
  };
  showHideModal(payload);

}else{
  return router.push(`/${route.params.marca}/panel/`);
}

};

const getGamas = async () => {

  if(brandSelected.value != loadedGama){
  
    if(!gamas.value.length){
        try {
          const { data, error } = await $api.gamas.getGamas(brandSelected.value ,{
              pick:['payload']
          });
          
          gamas.value = await data.value?.payload as unknown;
          setGamas(gamas.value);
          setLoadedGama(brandSelected.value);
            
      } catch (error) {
          //TODO: Controlar error
      } 
    }
  }
  else{
    gamas.value = storedGamas.value;
  }

};

const goToFavourite = () => {
  router.push({ path: `/${route.params.marca}/panel/favoritos/` });
};

const goToComparator = () => {
  router.push({ path: `/${route.params.marca}/panel/comparador/` });
};

await getGamas();

const navigateTo = (type) => {
  setSelectedGama(type);
  router.push({ path: `/${route.params.marca}/gama/` });
};

router.afterEach(async (to, from) => {
  menuItemSelected.value = 'modelos';
  isShowMenu.value = false;
});

watchEffect(() => {
    if (import.meta.client) {
        if (isShowMenu.value) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }
});

const isMarcosCycles = computed(()=>{
  return route.params.marca ==='marcos-cycles';
})

</script>

<template lang="pug">
header.app-header-brand
  .container.container-big
    .row.align-items-center
      .col
        nav.app-header-brand__nav
          .app-header-brand__nav-wrapper.d-flex.align-items-center.gap-3
            template(v-if="route.params.marca !=='marcos-cycles'")
              nuxt-link.app-header-brand__logo(:to="`/${route.params.marca}/`")
                span.icon(:class="`icon-${route.params.marca}`")
                img.img-fluid(
                  src="~/assets/images/logo-primary-marcos-automocion.png",  
                  alt="Logo Marcos Automoción")
            template(v-else)
              NuxtLink(:to="`/${route.params.marca}/`")
                span.icon(:class="`icon-${route.params.marca}`")
            .btn-menu.d-flex.align-items-center.gap-1(@click="toogleMenu" :class="{'ms-5':route.params.marca==='marcos-cycles'}")
              span.icon-menu
              span MENÚ
      .col.d-none.d-lg-flex.gap-3.justify-content-end
        .app-header-brand__personal(@click="showModalLogin")  
          span.icon-user
        .d-block
          button.btn-brand.btn-brand--cta(@click="showModalContact") Contacto
        .d-block
          nuxt-link.btn-brand.btn-brand--outlined-primary.btn-brand--medium(to="/") 
            img(src="~assets/images/logo-go-back.png", alt="", width="16")
            span VOLVER AL GRUPO
  .app-header-brand__content(v-if="isShowMenu")
    .app-header-brand__content-header.text-end(@click="toogleMenu") 
      span.icon-times
    .app-header-brand__content-body
      nav
        ul
          li(@click="setMenuselection('stock-vn')") 
            span {{route.params.marca==='marcos-cycles' ? 'Motos nuevas' : 'Coches nuevos'}}
            span.icon.icon-minus(v-if="menuItemSelected === 'stock-vn'")
            span.icon.icon-add(v-else)
            ul.submenu-mobile(v-if="menuItemSelected === 'stock-vn'") 
              li 
                nuxt-link(:to="`/${route.params.marca}/gama/`") Listado gama
              li 
                nuxt-link(:to="`/${route.params.marca}/coches-nuevos/`", v-if="!isMarcosCycles") Ofertas stock VN
                nuxt-link(:to="`/${route.params.marca}/motos-nuevas/`", v-else) Ofertas motos VN
              li(v-if="!isMarcosCycles")
                nuxt-link(:to="`/${route.params.marca}/coches-nuevos/kilometro-cero/`") Ofertas stock KM0
          li(v-if="customInfo.secciones_opcionales && customInfo.secciones_opcionales?.promociones_vn")
            nuxt-link(:to="`/${route.params.marca}/promociones-marcas/`")
              span Promociones {{route.params.marca==='marcos-cycles' ? 'MN' : 'VN'}}
              span.icon.icon-chevron-right
          li(v-if="brandSelected !== 'leapmotor'") 
            nuxt-link(:to="isMarcosCycles ? `/${route.params.marca}/motos-ocasion/`: `/${route.params.marca}/coches-ocasion/`")
              span {{route.params.marca==='marcos-cycles' ? 'Motos' : 'Coches'}} de ocasión
              span.icon.icon-chevron-right
          li( v-if="customInfo.secciones_opcionales && (customInfo.secciones_opcionales?.cita_previa || customInfo.secciones_opcionales?.promociones_posventa)" @click="setMenuselection('postventa')") 
            span Posventa
            span.icon.icon-minus(v-if="menuItemSelected === 'postventa'")
            span.icon.icon-add(v-else)
            ul.submenu-mobile(v-if="menuItemSelected === 'postventa'")
              li(v-if="customInfo.secciones_opcionales?.cita_previa") 
                nuxt-link(:to="`/${route.params.marca}/cita-previa-taller/`") Cita previa
              li(v-if="customInfo.secciones_opcionales?.promociones_posventa") 
                nuxt-link(:to="`/${route.params.marca}/promociones-posventa/`") Promociones
          li(v-if="customInfo.secciones_opcionales && customInfo.secciones_opcionales?.instalaciones_horarios") 
            nuxt-link(:to="`/${route.params.marca}/instalaciones/`")
              span Instalaciones y horarios
              span.icon.icon-chevron-right
          li(v-if="customInfo.secciones_opcionales && customInfo.secciones_opcionales?.actualidad") 
            nuxt-link(:to="`/${route.params.marca}/noticias/`")
              span Actualidad
              span.icon.icon-chevron-right
          //-li(v-if="customInfo.secciones_opcionales && (customInfo.secciones_opcionales.seguros || customInfo.secciones_opcionales.empresas)" @click="setMenuselection('servicios')") 
          li(@click="setMenuselection('servicios')") 
            span Servicios
            span.icon.icon-minus(v-if="menuItemSelected === 'servicios'")
            span.icon.icon-add(v-else)
            ul.submenu-mobile(v-if="menuItemSelected === 'servicios'")
              li(v-if="customInfo.secciones_opcionales?.seguros")  
                nuxt-link(:to="`/${route.params.marca}/seguros/`") Seguros
              li(v-if="customInfo.secciones_opcionales?.empresas")  
                nuxt-link(:to="`/${route.params.marca}/empresas/`") Empresas
          li(@click="showModalContact")   
            span Contacto
            span.icon.icon-chevron-right
        .d-block.d-lg-none.my-3
          nuxt-link.btn-brand.btn-brand--outlined-primary.btn-brand--large(to="/") 
            img(src="~assets/images/logo-go-back.png", alt="", width="16")
            span VOLVER AL GRUPO

        .app-header-brand__content-body-bottom
          ul
            li.body-bottom-li(@click="isAuthenticated ? goToFavourite(): showModalLogin()") 
              span.icon.icon-heart
              span Favoritos
            li.body-bottom-li(@click="isAuthenticated ? goToComparator(): showModalLogin()")
              span.icon.icon-arrow-filter
              span Comparador
          .personal(@click="showModalLogin" ) 
            span.icon.icon-user
            span Entra a tu cuenta

      .app-header-brand__content-selection
          .models(v-show="menuItemSelected === 'modelos'") 
            .model(v-for="(gama, index) in storedGamas.slice(0, 6)", :key="index") 
              img.img-fluid(:src="gama.thumbnail" :alt="`${gama.marca} ${gama.modelo}`")
              .title {{ gama.modelo }}
              nuxt-link.btn-brand-tertiary.btn-brand-tertiary--primary(:to="{path: `/${$route.params.marca}/gama/${gama.slug_modelo}/`, query: {type: gama.tipo_vehiculo}}") Ver ficha completa 
            .footer
              span.lead(@click="navigateTo('ALL')").text-uppercase Ver toda la gama #[span {{ brandsInfoStore.getBrandNameBySlug($route.params.marca) }}]
              .d-block
                template(v-if="route.params.marca==='marcos-cycles'")
                  .d-block.mb-2
                    button.btn-brand-tertiary.btn-brand-tertiary--secondary.test(@click="navigateTo('NUE')") 
                      span VER TODA LA GAMA 
                template(v-else) 
                  .d-block.mb-2
                    button.btn-brand-tertiary.btn-brand-tertiary--secondary.test(@click="navigateTo('NUE')") 
                      span VER TODA LA GAMA TURISMOS 
                  .d-block(v-if="getGamaComerciales.length>0")
                    button.btn-brand-tertiary.btn-brand-tertiary--secondary.test(@click="navigateTo('COM')") 
                      span VER TODA LA GAMA COMERCIALES
          .stock-vn.submenu(v-show="menuItemSelected === 'stock-vn'") 
            ul
              li 
                nuxt-link(:to="`/${route.params.marca}/gama/`") Listado gama
              li
                nuxt-link(:to="`/${route.params.marca}/coches-nuevos/`", v-if="!isMarcosCycles") Ofertas stock VN
                nuxt-link(:to="`/${route.params.marca}/motos-nuevas/`", v-else) Ofertas motos VN
              li(v-if="!isMarcosCycles")
                nuxt-link(:to="`/${route.params.marca}/coches-nuevos/kilometro-cero/`") Ofertas stock KM0
            .submenu-img 
              img.img-fluid(:src="customInfo.menu_imagen_nuevos", :alt="customInfo.menu_imagen_nuevos_alt")
          .postventa.submenu(v-show="menuItemSelected === 'postventa'") 
            ul
              li(v-if="customInfo.secciones_opcionales?.cita_previa")  
                nuxt-link(:to="`/${route.params.marca}/cita-previa-taller/`") Cita previa
              li(v-if="customInfo.secciones_opcionales?.promociones_posventa") 
                nuxt-link(:to="`/${route.params.marca}/promociones-posventa/`") Promociones
            .submenu-img 
              img.img-fluid(:src="customInfo.menu_imagen_posventa", :alt="customInfo.menu_imagen_posventa_alt")
          .services.submenu(v-show="menuItemSelected === 'servicios'") 
            ul
              li(v-if="customInfo.secciones_opcionales?.seguros")  
                nuxt-link(:to="`/${route.params.marca}/seguros/`") Seguros
              li(v-if="customInfo.secciones_opcionales?.empresas")  
                nuxt-link(:to="`/${route.params.marca}/empresas/`") Empresas
            .submenu-img 
              img.img-fluid(:src="customInfo.menu_imagen_servicios", :alt="customInfo.menu_imagen_servicios_alt")
</template>

<style lang="scss" scoped>
@import 'index';
</style>